<template>
  <div class="feed-comment-container" :class="{'feed-reply-comment-container':isReply}">
    <div class="feed-comment-container__body" :class="{'feed-reply-comment-container__body':isReply}">
      <div class="comment-section">
        <div class="comment-container">
          <div class="comment">
            <div class="comment__body">
              <div class="image">
                <img
                  :src="feedComment.user.profile_picture"
                />
              </div>
              <div class="details">
                <div class="details__header">
                  <div class="name">
                    <p>{{ feedComment.user.full_name }} <span>{{ feedComment?.created }}</span></p>
                  </div>
                  <a-dropdown
                    v-if="currentLoginUser == feedComment?.user?.id"
                    :trigger="['click']"
                    class="dropdown"
                    @click.stop=""
                    placement="bottomRight"
                  >
                    <a class="dropdown-link">
                      <i class="icon icon-vertical_three-dots"></i>
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item key="0" @click.stop="toggleEditComment(feedComment)">
                          <a>Edit</a>
                        </a-menu-item>
                        <a-menu-item key="1" @click.stop="toggleDeleteCommentModal(feedComment)">
                          <a class="remove-btn">Remove</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
                <div class="details__body">
                  <p v-if="!isCommentEditing" class="details__body--comment">
                   {{  feedComment.text }}
                  </p>
                  <div v-else class="details__body--edit">
                    <div class="btn-textarea">
                      <textarea name="" id="" rows="2" v-model="editedComment.text"></textarea>
                      <a-button :loading="editCommentLoader" @click.stop="editComment()">
                        <i class="icon icon-send"></i>
                      </a-button>
                    </div>
                    <button class="cancel-btn" @click="toggleEditComment()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="comment__footer">
              <button
                class="thumbs-btn"
                :class="{'like': feedComment.current_user_like}"
                @click.stop="likeAndDislikeComment(feedComment, 'like')"
              >
                <i class="icon icon-thumbs_up"></i>
                <p>{{feedComment?.like_count}}</p>
              </button>
              <button
                class="thumbs-btn"
                :class="{'like': feedComment.current_user_dislike}"
                @click.stop="likeAndDislikeComment(feedComment, 'dislike')"
              >
                <i class="icon icon-thumbs_up icon icon-thumbs_up dislike"></i>
                <p>{{feedComment?.dislike_count}}</p>
              </button>
              <button class="btn" @click="togglerReplyBtn" v-if="!isReply">
                Reply
              </button>
            </div>
          </div>
          <feed-comments v-for="feedComment in feedComment.child" :key="feedComment.id" :feedComment="feedComment" :feedId="feedId" :isReply="true"></feed-comments>
          <!-- <feed-comment-reply v-for="feedReplyComment in feedComment.child" :key="feedReplyComment.id" :feedReplyComment="feedReplyComment" :feedId="feedId"></feed-comment-reply> -->
        </div>
        <div class="reply" v-if="reply">
          <feed-comment-input :loadingState="createCommentLoader" @create="createComment"></feed-comment-input>
        </div>
      </div>
    </div>
  </div>
  <DeleteModal 
    :visible="isRemoveComment"
    @handle-close="()=>{isRemoveComment = false}"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="deleteLoader"
    @delete-record="deleteComment"
  />
</template>

<script>
import { h } from "vue";
import { mapActions, mapGetters } from "vuex";
import DeleteModal from "../BaseComponents/DeleteModal.vue";
// import FeedCommentReply from "./FeedCommentReply.vue";
import FeedCommentInput from "./FeedCommentInput.vue";

export default {
  name: "FeedComments", 
  components:{
    DeleteModal,
    // FeedCommentReply,
    FeedCommentInput
  },
  props:{
    feedId:{
      type: String,
      default: ()=>(''),
      required:true
    },
    feedComment:{
      type: Object,
      default: ()=>({}),
    },
    isReply:{
      type: Boolean,
      default: () => (false)
    }
  },
  data() {
    return {
      selectedValue: "most-relevant",
      customIcon: h("span", { class: "arrow-icon" }),
      reply: false,
      comment: '',
      replyComment: '',
      createCommentLoader: false,
      isRemoveComment: false,
      deleteLoader: false,
      deleteCommentId: '',
      isCommentEditing: false,
      editCommentLoader: false
    };
  },
  computed:{
    ...mapGetters({
      userId: 'auth/getUserId',
      feedComments: 'feed/feedComments',
      currentLoginUser: 'profile/currentLoginUser'
    }),
  },
  methods: {
    ...mapActions({
      createFeedComments: 'feed/createFeedComments',
      fetchComments: 'feed/fetchComments',
      deleteComments: 'feed/deleteComments',
      editComments: 'feed/editComments',
      likeDislikeComments: 'feed/likeDislikeComments',
      deleteLikeDislikeComments: 'feed/deleteLikeDislikeComments'
    }),
    togglerReplyBtn() {
      this.reply = !this.reply;
    },
    async createComment(comment){
      try{
        const commentPayload={
          user: this.userId,
          text: comment,
          parent: this.feedComment?.id,
        }
        this.createCommentLoader = true
        await this.createFeedComments({postId:this.feedId, payload:commentPayload })
        if (this.reply)
          this.togglerReplyBtn()
      }
      catch(err){
        console.log(err)
      }
      finally{
        this.createCommentLoader = false
      }
    },
    toggleDeleteCommentModal(comment=null){
      this.deleteCommentId = comment?.id
      this.isRemoveComment = !this.isRemoveComment

    },
    async deleteComment(){
      this.deleteLoader = !this.deleteLoader
      try{

        await this.deleteComments({postId:this.feedId, commentId: this.deleteCommentId})
      }
      catch(err){
        console.log(err)
      }
      finally{
        this.deleteLoader = !this.deleteLoader
        this.toggleDeleteCommentModal()
      }
    },
    async editComment(){
      try{
        const {text, parent, id} = this.editedComment
        this.editCommentLoader = !this.editCommentLoader
        await this.editComments({postId:this.feedId, commentId:id, text, parent })
      }
      catch(err){
        console.log(err)
      }
      finally{
        this.editCommentLoader = !this.editCommentLoader
        this.toggleEditComment()
      }

    },
    toggleEditComment(comment=null){
        this.isCommentEditing = !this.isCommentEditing
        this.editedComment = {...comment}
    },
    async likeAndDislikeComment(comment, action){
      try{
        if ((action === 'like' && comment?.current_user_like) || (action === 'dislike' && comment?.current_user_dislike)){
          await this.deleteLikeDislikeComments({commentId: comment?.id})
        }
        else{
          await this.likeDislikeComments({commentId: comment?.id, action})
        }
      }
      catch(err){
        console.log("ERR", err)
      }
    }
  }
};
</script>

<style lang="scss">
.feed-comment-container {
  .comment-type-input {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0.5rem 0;
    img {
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      object-fit: cover;
    }
    textarea {
      padding: 1.1rem 1.5rem 1rem;
      margin: 0;
      border: 1px solid $color-dark-grey-5;
      font-size: 1.4rem;
      font-family: $font-primary;
      box-shadow: none;
      outline: none;
      line-height: 1.7rem;
      color: $color-black;
      background-color: transparent;
      resize: none;
      width: 100%;
      border-radius: 3rem !important;
      &::placeholder {
        color: $color-black;
        opacity: 0.5;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      padding: 0;

      & .icon-send {
        font-size: large;
      }
      .disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      .ant-btn {
        width: 4.5rem;
        background-color: $color-primary;
        border: none;
        outline: none;
        height: 3rem;
        color: $color-white;
        border-radius: 100px !important;
        padding: 0 !important;
        span {
          line-height: normal;
        }
        .anticon {
          .anticon-spin {
            font-size: 1.4rem;
            color: $color-primary;
          }
        }
        &:hover,
        &:focus {
          background-color: $color-primary;
          color: $color-white;
        }
        .icon {
          font-size: 1.5rem;
          display: block;
          color: $color-white;
        }
      }
    }
  }
  &__header {
    padding: 1rem 2rem;
    .ant-select {
      height: fit-content;
    //   display: flex;
    //   align-items: baseline;
      width: fit-content;
      .ant-select-selector {
        height: auto !important;
        padding: 0;
        background-color: transparent;
        border: none;
        .ant-select-selection-search {
          display: none;
        }
        .ant-select-selection-item {
          height: max-content;
          display: flex;
          padding: 0;
          padding-right: 2.2rem;
          h1 {
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: normal;
            color: $color-black;
            opacity: 0.6;
            margin-top: 0.2rem;
          }
        }
      }
      .ant-select-arrow {
        right: 0;
        opacity: 0.6;
        .arrow-icon {
          background-image: url("../../assets/icons/down-arrow-icon.png");
          height: 1.3rem;
          width: 1.3rem;
          display: block;
          background-size: contain;
          background-position-y: top;
        }
      }
    }
  }
  &__body {
    padding: 0 2rem;
    .comment-section {
      .comment-container {
        margin-bottom: 1rem;
        &:last-child {
          margin-bottom: 0;
        }
        .comment {
          margin-bottom: 1.5rem;
          &__body {
            display: flex;
            gap: 1rem;
            padding: 1rem;
            background-color: #f5f5f5;
            border-radius: 1rem;
            overflow: hidden;
            .image {
              width: 3rem;
              height: 3rem;
              border-radius: 100%;
              img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                object-fit: cover;
              }
            }
            .details {
              width: calc(100% - 3rem);
              &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 0.5rem;
                margin-top: 0.7rem;
                .name {
                  display: flex;
                  align-items: center;
                  p {
                    font-size: 1.4rem;
                    font-family: $font-primary-medium;
                    margin-bottom: 0;
                    line-height: normal;
                    color: $color-black;
                    span {
                      font-family: $font-primary;
                      opacity: 0.8;
                    }
                  }
                }
                .dropdown {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .icon {
                    font-size: 1.5rem;
                    color: $color-black;
                  }
                }
              }
              &__body {
                margin: 0.7rem 2rem 0.7rem 0;
                &--comment {
                  font-size: 1.4rem;
                  font-family: $font-primary;
                  margin-bottom: 0;
                  line-height: 1.8rem;
                  color: $color-black;
                }
                &--edit {
                  margin-top: 1rem;
                  .btn-textarea {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: $color-white;
                    padding: 1rem 1rem;
                    margin-bottom: 0.2rem;
                    border-radius: 0.5rem;
                    textarea {
                      width: 100%;
                      background-color: transparent;
                      border: none;
                      outline: none;
                      font-size: 1.6rem;
                      font-family: $font-primary;
                      line-height: 1.8rem;
                      resize: none;
                      border-radius: 0 !important;
                      &::-webkit-scrollbar-track {
                        background-color: #03030309;
                      }

                      &::-webkit-scrollbar {
                        width: 0.4rem;
                        background-color: transparent;
                        border-radius: 4rem;
                      }

                      &::-webkit-scrollbar-thumb {
                        background-color: $color-primary;
                        border-radius: 4rem;
                      }
                    }
                    .ant-btn {
                      border: none;
                      height: fit-content;
                      width: fit-content;
                      line-height: normal;
                      margin-left: 1rem;
                      cursor: pointer;
                      background-color: transparent;
                      display: flex;
                      align-items: center;
                      padding: 0;
                      .icon {
                        font-size: 2rem;
                        color: $color-primary;
                      }
                      .anticon {
                        .anticon-spin {
                          font-size: 2rem;
                        }
                      }
                    }
                  }
                  .cancel-btn {
                    border: none;
                    background-color: transparent;
                    color: $color-black;
                    font-size: 1.2rem;
                    font-family: $font-primary-medium;
                    opacity: 0.6;
                    cursor: pointer;
                    margin-left: 0.5rem;
                  }
                }
              }
            }
          }
          &__footer {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 0.6rem 1rem;
            .btn {
              border: none;
              outline: none;
              background-color: transparent;
              height: fit-content;
              width: fit-content;
              display: flex;
              align-items: center;
              font-size: 1.4rem;
              font-family: $font-primary-medium;
              color: $color-black;
              line-height: 1.3rem;
              cursor: pointer;
              img {
                width: 1.3rem;
                display: block;
                transform: rotateY(180deg);
                margin-right: 0.6rem;
              }
            }
            .thumbs-btn {
              border: none;
              outline: none;
              background-color: transparent;
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 0.5rem;
              .icon {
                color: #1c1b1f;
                font-size: 1.7rem;
                display: block;
                margin-bottom: auto;
              }
              .dislike {
                transform: rotate(-180deg);
              }
              p {
                font-size: 1.4rem;
                margin-bottom: 0;
                color: $color-black;
                line-height: 1.6rem;
                font-family: $font-primary-medium;
              }
            }
            .like {
              .icon {
                color: $color-primary;
              }
            }
          }
        }
        .reply-comment {
          margin-left: 4rem;
          .reply {
            margin-left: 0;
          }
        }
      }
      .reply {
        margin-left: 4rem;
        background-color: #f5f5f5;
        border-radius: 1rem;
        margin-bottom: 1.6rem;
        .feed-comment-type-input {
          padding: 0.8rem 1rem;
          margin: 1rem 0 0;
        }
      }
    }
  }
}
.feed-reply-comment-container {
  &__body {
    padding: 0 0 0 4rem; 
  }
}
.ant-select-dropdown {
  .ant-select-dropdown-content {
    .rc-virtual-list {
      .rc-virtual-list-holder {
        div {
          .rc-virtual-list-holder-inner {
            .feed-comments-select-item {
              padding: 1.2rem 1.8rem;
              .ant-select-item-option-content {
                h1 {
                  font-size: 1.4rem;
                  font-family: $font-primary-medium;
                  margin-bottom: 0.5rem;
                  line-height: normal;
                  color: $color-black;
                }
                p {
                  font-size: 1.2rem;
                  font-family: $font-primary;
                  margin-top: 0;
                  line-height: 1.6rem;
                  color: $color-black;
                  margin-bottom: 0;
                  font-weight: 400;
                  max-width: 30rem;
                  white-space: normal;
                  opacity: 0.7;
                }
              }
              &:hover {
                background-color: rgba(238, 238, 238, 0.801);
              }
            }
          }
        }
      }
    }
  }
}
</style>
