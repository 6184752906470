<template>
  <a-skeleton
    active
    :avatar="{ shape: 'square' }"
    :title="null"
    :paragraph="{
      rows: 2,
      width: ['30%', '100%'],
    }"
    class="comment-skeleton"
  />
</template>
<style lang="scss">
.comment-skeleton {
  width: 100%;
  padding: 1rem 2rem 1.5rem;
  .ant-skeleton-header {
    padding: 0;
    height: fit-content;
    height: 3rem;
    width: 3rem;
    margin-right: 1rem;
    display: block;
    border-radius: 100%;
    .ant-skeleton-avatar {
      height: 3rem;
      width: 3rem;
      line-height: inherit;
      border-radius: 100%;
    }
  }
  .ant-skeleton-content {
    margin-bottom: 1rem;
    .ant-skeleton-paragraph {
      margin: 0 !important;
      li {
        height: 1.5rem;
      }
    }
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 1rem;
  }
}
</style>
