<template>
  <div class="feed-recommended-sidebar">
    <div class="feed-recommended-sidebar__header">
      <h1 class="feed-recommended-sidebar__header--title">
        Recommended contributors to your feed
      </h1>
    </div>
    <div class="feed-recommended-sidebar__body">
      <div class="recommended">
        <div class="recommended__header">
          <h3 class="recommended__header--title">
            Recommended Contacts
          </h3>
          <button v-if="recommendedContributors.length > 0" @click="()=>{ showContactRecommendations = true}" class="recommended__header--btn"><span>See All</span></button>
        </div>
        <div class="recommended__body">
          <template v-if="recommendedLoader">
            <skeleton-loader
              v-for="index in 3"
              :key="index"
            />
          </template>
        <template v-else>
          <div class="recommended-item" v-for="connection in recommendedContributors.slice(0,4)" :key="connection.id">
                <div class="recommended-item__image">
                  <img
                    :src="connection.profile_picture"
                    alt="Logo"
                    class="recommended-item__image--img"
                  />
              </div>
              <div class="recommended-item__details" @click.stop="redirectToUser(connection)">
                <h1 class="recommended-item__details--name">{{connection.full_name}}</h1>
                <p class="recommended-item__details--description">{{ connection?.job_title }}</p>
              </div>
            <!-- </span> -->
            <a-button class="recommended-item__btn" @click="addContributors(connection)" :loading="connection.isLoading" :disabled="connection.isLoading">Follow</a-button>
            <!-- <button @click="addContributor(connection)">Add</button> -->
          </div>
          <p class="recommended__body--message" v-if="recommendedContributors.length === 0">
            No recommendations found
          </p>
        </template>
        </div>
      </div>
      <div class="recommended">
        <div class="recommended__header">
          <h3 class="recommended__header--title">
            Recommended Organizations
          </h3>
          <button v-if="recommendedFollowedOrganizations.length > 4" @click="()=>{ showOrganizationRecommendations = true}" class="recommended__header--btn"><span>See All</span></button>
        </div>
        <div class="recommended__body">
          <template v-if="recommendedLoader">
            <skeleton-loader
              v-for="index in 3"
              :key="index"
            />
          </template>
          <template v-else>
            <div class="recommended-item" v-for="organization in recommendedFollowedOrganizations.slice(0,4)" :key="organization?.id">
              <div class="recommended-item__image">
                <img
                  :src="organization.profile_picture"
                  alt="Logo"
                  class="recommended-item__image--img"
                />
              </div>
              <div class="recommended-item__details">
                <h2 class="recommended-item__details--name">{{organization.full_name}}</h2>
                <p class="recommended-item__details--description">
                  {{ organization?.message }}
                </p>
              </div>
              <a-button class="recommended-item__btn" @click="followOrganization(organization)" :loading="organization.isLoading" :disabled="organization.isLoading">Follow</a-button>
            </div>
            <p class="recommended__body--message" v-if="recommendedFollowedOrganizations.length === 0">
              No recommendations found
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
  <AllUsersModal
    :visible="showContactRecommendations ||  showOrganizationRecommendations"
    :users="recommendedUsers"
    @add-recommendation="(user)=>{activeModule === 'recommendedContacts' ? addContributors(user) : followOrganization(user)}"
    @closeAllUserModal="closeAllUserModal"
    :module="activeModule"
    :modalTitle="modalTitle"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import skeletonLoader from "../BaseComponents/ConnectionCardSkeleton.vue"
import AllUsersModal from '@/components/BaseComponents/AllUsersModal.vue';
export default{
  components:{
    skeletonLoader,
    AllUsersModal
  },
  data: () =>({
    recommendedLoader: false,
    index: -1,
    contributorArray: [],
    showContactRecommendations: false,
    showOrganizationRecommendations: false,
    organizationContributorArray:[]

  }),
  computed:{
    ...mapGetters({
      recommendedConnections: 'feed/recommendedConnections',
      recommendedOrganizations: 'feed/recommendedOrganizations',
      allContributors: 'feed/getAllContributors',
      followedContributors: 'feed/getContributors',
      allOrganizations: 'feed/allOrganizations',
      authUserInformation: 'profile/userInformation',

    }),
    recommendedContributors(){
        return this.contributorArray.filter(contributor=> !this.followedContributors.find(followContributor=>followContributor.id === contributor.id))

    },
    recommendedFollowedOrganizations(){
      return this.organizationContributorArray
    },
    recommendedUsers(){
      if (this.showContactRecommendations)
        return this.recommendedContributors
      else if (this.showOrganizationRecommendations)
        return this.recommendedFollowedOrganizations
      else
        return []
    },
    activeModule(){
      if (this.showContactRecommendations)
        return 'recommendedContacts'
      else if (this.showOrganizationRecommendations)
        return 'recommendedOrganization'
      else
        return ''
    },
    modalTitle(){
      if (this.showContactRecommendations)
        return 'Recommended Contacts'
      else if (this.showOrganizationRecommendations)
        return 'Recommended Organizations'
      else
        return 'All Invited Users'
    }
    
  },
  watch:{
    allContributors:{
      handler(val){
        this.contributorArray = val
      },
      immediate: true
    },
    allOrganizations:{
      handler(val){
        this.organizationContributorArray = val
      },
      immediate: true
    }
  },
  async mounted(){
    this.recommendedLoader = true
    await this.fetchRecommendedConnections()
    await this.fetchRecommendedOrganization()
    this.recommendedLoader = false
  },
  
  methods:{
    ...mapActions({
      fetchRecommendedConnections:'feed/fetchRecommendedConnections',
      fetchRecommendedOrganization:'feed/fetchRecommendedOrganization',
      setSelectedUser:'profile/setSelectedUser',
      followRecommendedOrganization: 'feed/followRecommendedOrganization'
    }),
    redirectToUser(user) {

      this.setSelectedUser(user)
      this.$store.dispatch("profile/fetchUserProfile");
      this.$router.push({
        path: `/user/${user.username}/`
      });
      // this.getUserInformation();
    },
    async addContributors(contributor) {
      try {
        // this.recommendedContributors = this.recommendedContributors.map(recommendedContributor=>{return recommendedContributor.id === contributor.id ? {...recommendedContributor, isLoading:true} : {...recommendedContributor}})
        this.contributorArray = this.recommendedContributors.map(recommendedContributor=>{
          if (recommendedContributor.id === contributor.id){
             return {
              ...recommendedContributor,
              isLoading:true
            }
          }
          else return {
            ...recommendedContributor,
              isLoading: false
          }
        })
        const payload = {
          add_contributors: [contributor.id],
          remove_contributors: []
        }
          await this.$store.dispatch("feed/addContributor", payload);
          await this.getFollowes('not_contributors');
          await this.getFollowes();
          this.$showToast({message:'Contributor added successfully', type:'success'})
      } catch (error) {
        this.contributorArray = this.recommendedContributors.map(recommendedContributor=>({...recommendedContributor, isLoading:false}))
        this.$showToast({message:'Something went wrong. Please try again later', type:'failure'})
      } 
    },
    async getFollowes(relation='contributors', account_type='basic') {
      await this.$store.dispatch("feed/fetchContributorsAndOrganizations", {relation, account_type, user_id:this.authUserInformation?.id});
    },
    closeAllUserModal(){
      this.showContactRecommendations = false
      this.showOrganizationRecommendations = false
    },
    async followOrganization(organization){
      try{
        this.organizationContributorArray = this.recommendedFollowedOrganizations.map(recommendedContributor=>{
          if (recommendedContributor.id === organization.id){
             return {
              ...recommendedContributor,
              isLoading:true
            }
          }
          else return {
            ...recommendedContributor,
              isLoading: false
          }
        })
        // await this.followRecommendedOrganization({organizationId: organization?.id})
        const payload = {
          add_contributors: [organization.id],
          remove_contributors: []
        }
        await this.$store.dispatch("feed/addContributor", payload);
        await this.getFollowes('not_contributors', 'organization');
        await this.getFollowes('contributors', 'organization');
        this.$showToast({message:`${organization?.full_name} added as a contributor`, type:'success'})
      }
      catch(err){
        this.organizationContributorArray = this.allOrganizations.map(recommendedContributor=>({...recommendedContributor, isLoading:false}))
        this.$showToast({message:"Something went wrong. Please try again later.", type:'failure'})
      }
    }
    
  
  }
}
</script>

<style lang="scss">
.feed-recommended-sidebar {
  border: 1px solid $color-dark-grey-5;
  border-radius: 2rem;
  background-color: $color-white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: sticky;
  top: 0;
  padding-bottom: 2rem;
  &__header {
    padding: 1.7rem 2.8rem 1.5rem;
    background-color: $color-white;
    border-start-start-radius: 2rem;
    border-start-end-radius: 2rem;
    &--title {
      font-size: 1.8rem;
      font-family: $font-primary-bold;
      margin-bottom: 0;
      line-height: 2.6rem;
      color: $color-black;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
              line-clamp: 1; 
      -webkit-box-orient: vertical;
    }
  }
  &__body {
    height: calc(100vh - 20.7rem);
    overflow-y: auto;
    .recommended {
      padding: 2.6rem 3.1rem;
      border-top: 1px solid $color-dark-grey-5;
      &__header {
        margin-bottom: 2.4rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &--title {
          font-size: 1.8rem;
          font-family: $font-primary-medium;
          margin-bottom: 0;
          line-height: 2.2rem;
          color: $color-black;
        }
        &--btn {
          border: none;
          outline: none;
          background-color: transparent;
          width: fit-content;
          height: fit-content;
          line-height: normal;
          font-size: 1.4rem;
          font-family: $font-primary;
          color: $color-primary;
          cursor: pointer;
          span {
            display: inline-flex;
            width: max-content;
          }
        }
      }
      &__body {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        .recommended-item {
          display: flex;
          align-items: center;
          gap: 1.6rem;
          &__image {
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 100%;
            cursor: pointer;
            &--img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              object-fit: cover;
              background-color: #ebebeb;
            }
          }
          &__details {
            width: calc(100% - 15.5rem);
            &--name {
              font-size: 1.6rem;
              font-family: $font-primary-medium;
              color: $color-black;
              line-height: 1.8rem;
              margin-bottom: 0;
              cursor: pointer;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
                      line-clamp: 1; 
              -webkit-box-orient: vertical;
            }
            &--description {
              font-size: 1.2rem;
              font-family: $font-primary;
              color: $color-black;
              line-height: 1.5rem;
              margin-bottom: 0;
              opacity: 0.7;
              margin-top: 0.5rem;
            }
          }
          &__btn {
            width: 7rem;
            background-color: $color-primary;
            border: none;
            display: block;
            height: 3.4rem;
            font-size: 1.4rem;
            font-family: $font-primary-medium !important;
            color: $color-white;
            margin-left: auto;
            padding: 0 !important;
            span {
              line-height: normal;
            }
            .anticon {
              .anticon-spin {
                font-size: 1.7rem !important;
                color: $color-primary !important;
              }
            }
          }
        }
        .connection-card-skeleton {
          margin: 0 !important;
          .ant-skeleton-header {
            .ant-skeleton-avatar {
              border-radius: 100%;
            }
          } 
        }
        &--message {
          text-align:center;
          font-size: 1.6rem;
          font-family: $font-primary;
          margin-bottom: 0;
          line-height: 2.2rem;
          color: $color-black;
          opacity: 0.8;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 0.3rem;
      height: 0.3rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 0.8rem;
      height: 0.3rem;
    }
  }
}
</style>
