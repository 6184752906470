<template>
  <div class="feed-upload-container">
    <div class="feed-upload-container__header">
      <img :src="user.profile.profile_picture" alt="Image" class="feed-upload-container__header--image" />
      <EditorContent :editor="editor" />
    </div>
    <div class="feed-upload-container__footer">
      <div class="feed-upload-container__footer--buttons">
        <button class="upload-btn" @click="uploadFeed('attacheMedia')">
          <img src="@/assets/icons/file-upload-icon.png" alt="Upload Icon" />
          Upload Content
        </button>
      </div>
      <a-button @click="postText"
        :class="['feed-upload-container__footer--post-btn', { 'feed-upload-container__footer--disabled': uploadContent.length == 0 }]"
        :disabled="isPostButtonDisabled"
        :loading="loading"
      >
        Post
      </a-button>
    </div>
  </div>
  <upload-modal
    :visible="visibleFeedUploadModal"
    @closeFeedUploadModal="closeFeedUploadModal"
    :uploadMedia="uploadMedia"
    :uploadVideo="uploadVideo"
    :uploadArticleLink="uploadArticleLink"
    :text="uploadContent"
    @emptyParentText="emptyTextContent"
  >
  </upload-modal>
</template>

<script>
import { mapActions } from "vuex";
import UploadModal from "./UploadModal.vue";
import Placeholder from "@tiptap/extension-placeholder";
import {Editor, EditorContent} from "@tiptap/vue-3";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import wordLimitExtension from "@/utils/TipTapEditor/WordLimitExtension";

export default {
  data() {
    return {
      visibleFeedUploadModal: false,
      uploadMedia: false,
      uploadVideo: false,
      uploadArticleLink: false,
      // uploadContent: '',
      loading: false,
      editor: null,
    };
  },
  components: {
    UploadModal,
    EditorContent,
  },
  computed: {
    user() {
      return this.$store.getters["profile/userInformation"];
    },
    userId() {
      return this.$store.getters["auth/getUserId"];
    },
    uploadContent:{
      get(){
        return this.editor.getHTML() ?? ''
      },
      set(content){
        this.editor.commands.setContent(content)
      }
    },
    isPostButtonDisabled(){
      return this.editor.getText() === '' ? true : false
    }
  },
  created(){
    this.initiateEditor()
  },
  beforeUnmount() {
    this.editor.destroy()
  },
  methods: {
    ...mapActions("feed", ["createFeed","fetchFeedList", "updateFeedPageSize", "fetchActivity"]),
    async postText() {
      try{
        this.loading = true
        const payload = {
          text: this.uploadContent,
          files: this.mediaId ? [this.mediaId] : [],
          article: null,
          shared: null,
          content_type: 'text'
        };

        await this.createFeed(payload);
        this.updateFeedPageSize(1)
          .then(() => {
            this.fetchFeedList();
          })
          .catch((error) => {
            console.error("Error creating post:", error);
          });
          this.fetchActivity({user_id:this.userId, type:'post'})
          this.uploadContent = ''
          this.$showToast({message:'Your post has been successfully published.', type:'success'})
      }
      catch(err){
        this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
      }
      finally{
        this.loading = false
      }
    },
    uploadFeed(type) {
      // Reset all states
      // this.uploadMedia = false;
      // this.uploadVideo = false;
      // this.uploadArticleLink = false;
      // // Set the appropriate state based on the type
      // switch (type) {
      //   case "attacheMedia":
          this.uploadMedia = true;
      //     break;
      //   case "attacheVideo":
      //     this.uploadVideo = true;
      //     break;
      //   case "attacheArticleLink":
      //     this.uploadArticleLink = true;
      //     break;
      // }
      // Show the modal
      this.visibleFeedUploadModal = true;
    },
    closeFeedUploadModal() {
      // Reset all states and hide modal
      this.visibleFeedUploadModal = false;
      this.uploadMedia = false;
      this.uploadVideo = false;
      this.uploadArticleLink = false;
    },
    emptyTextContent(){
      this.uploadContent = ''
    },
    initiateEditor(){
      this.editor =  new Editor ({
      extensions: [
        StarterKit,
        TextStyle,
        HorizontalRule.configure({
          HTMLAttributes: {
            class: "",
          },
        }),
        wordLimitExtension.configure({
          maxWords: 500,  // Configure the max word limit
        }),
        Link.configure({
          openOnClick: true,
        }),
        Placeholder.configure({
          placeholder: "Write your thoughts...", // Placeholder text
        }),
      ],
    })
    },
  },
};
</script>

<style lang="scss">
.feed-upload-container {
  border: 1px solid $color-dark-grey-5;
  border-radius: 2rem;
  background-color: $color-white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 2.8rem 2.9rem;
  @include respond(tab-port) {
    padding: 2.6rem 2.5rem;
  }
  &__header {
    display: flex;
    align-items: flex-start;
    &--image {
      width: 4.2rem;
      height: 4.2rem;
      border: 2px solid $color-white;
      border-radius: 100%;
      margin-right: 0.8rem;
      @include respond(phone-x-small) {
        width: 5rem;
        height: 5rem;
      }
    }
    div {
      width: calc(100% - 4.2rem);
      @include respond(phone-x-small) {
        width: calc(100% - 5rem);
      }
    }
    .ProseMirror {
      border: 1px solid $color-dark-grey-5;
      border-radius: 0.5rem !important;
      max-height: 10.5rem;
      overflow: auto;
      padding: 0.9rem 1rem;
      width: 100%;
      font-size: 1.4rem;
      color: $color-black;
      outline: none;
      p {
        margin-bottom: 0;
      }
      &::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 0.8rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 0.8rem;
      }
      @include respond(phone-x-small) {
        font-size: 1.8rem;
      }
    }
    .ProseMirror p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: #6d6d6d;
      pointer-events: none;
      height: 0;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.7rem;
    &--buttons {
      display: flex;
      align-items: center;
      gap: 3.4rem;
      .upload-btn {
        border: none;
        outline: none;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: $color-black;
        font-family: $font-primary;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        background-color: transparent;
        cursor: pointer;
        img {
          width: 2.2rem;
        }
        @include respond(phone-x-small) {
          font-size: 1.8rem;
          line-height: 2.8rem;
          img {
            width: 2.6rem;
          }
        }
      }
    }
    .ant-btn {
      width: 9.7rem;
      height: 3.6rem;
      font-size: 1.4rem;
      font-family: $font-primary-medium;
      background-color: $color-primary;
      color: $color-white;
      border-radius: 100px !important;
      outline: none;
      border: none;
      cursor: pointer;
      padding: 0 !important;
      span {
        line-height: normal;
      }
      .anticon {
        .anticon-spin {
          font-size: 1.8rem !important;
          color: $color-primary !important;
        }
      }
      &:hover,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
      @include respond(phone-x-small) {
        width: 12rem;
        height: 4.5rem;
        font-size: 1.8rem;
        .anticon {
          .anticon-spin {
            font-size: 2.2rem !important;
          }
        }
      }
    }
    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
</style>
