<template>
  <a-modal
    :visible="visible"
    @cancel="closeModal"
    :footer="null"
    class="view-feed-modal"
    :title="null"
  >
    <div class="view-feed-modal-container">
      <div class="view-feed-modal-container__header">
        <div class="view-feed-modal-container__header--user">
          <div class="view-feed-modal-container__header--user__image">
            <img :src="viewFeed?.user?.profile_picture" alt="Image" />
          </div>
          <div class="view-feed-modal-container__header--user__details">
            <h1>{{ viewFeed?.user?.full_name }}</h1>
            <!-- <p>Frontend Developer at Devroute</p> -->
            <p class="time">{{ viewFeed?.created_at }}</p>
          </div>
        </div>
      </div>
      <div class="view-feed-modal-container__body">
        <div
          class="view-feed-modal-container__body--description"
          v-if="viewFeed?.text?.length > 0 && viewFeed?.content_type === 'share'"
        >
          {{ viewFeed.text }}
        </div>
        <div class="view-feed-modal-container__body--repost-card"> 
           <GlocalRepostCard :feedObject="viewFeed?.content_type === 'share' ? feedObject?.shared : feedObject" :isCommentView="viewFeed?.content_type === 'share' ? false : true"/>
        </div>
        <div class="view-feed-modal-container__body--footer">
          <the-reaction-badge-counter
            v-if="feedObject?.feed_reactions && Object.keys(feedObject.feed_reactions).length > 0"
            :totalArticleReactions="feedObject?.feed_reactions"
            :userReactionRepresentation="feedObject?.users_reaction_representation"
            :articleId="feedObject?.id"
            :feedTotalComments="feedObject?.total_comments ?? 0"
          >
          </the-reaction-badge-counter>
          <div class="action-bar">
            <the-reactions
              :articleId="viewFeed.id"
              :userReaction="viewFeed.user_reaction"
              @updateReaction="updateReaction"
            ></the-reactions>
            <span class="action-bar__line"></span>
            <button class="action-bar__btn">
              <img
                src="@/assets/icons/comment-icon.png"
                style="width: 2rem; object-fit: contain;"
              />
              Comment
            </button>
            <span class="action-bar__line"></span>
            <button class="action-bar__btn">
              <img src="@/assets/icons/reply.svg" style="width: 1.8rem;" />
              Share
            </button>
          </div>
          <div class="comment-type-input">
            <feed-comment-input @create="createComment" :loadingState="commentLoader"></feed-comment-input>
          </div>
          <template v-if="fetchCommentLoader">
            <comments-skeleton v-for="commentSkeleton in 2" :key="commentSkeleton" />
          </template>
          <template v-else>
            <feed-comments :feedId="feedObject.id" v-for="feedComment in feedComments" :key="feedComment.id" :feedComment="feedComment" />
          </template>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import TheReactions from "../BaseComponents/TheReactions.vue";
import TheReactionBadgeCounter from "../BaseComponents/TheReactionBadgeCounter.vue";
import FeedComments from "./FeedComments.vue";
import GlocalRepostCard from "./GlocalRepostCard.vue";
import { mapGetters, mapActions } from "vuex";
import FeedCommentInput from "./FeedCommentInput.vue";
import CommentsSkeleton from "../BaseComponents/CommentsSkeleton.vue";

export default {
  data() {
    return {
      reRender: 0,
      commentLoader: false,
      fetchCommentLoader: false
    };
  },
  components: {
    TheReactions,
    TheReactionBadgeCounter,
    FeedComments,
    GlocalRepostCard,
    FeedCommentInput,
    CommentsSkeleton
  },
  props: {
    visible: Boolean,
    viewFeed: Object,
    feedObject: {
      type: Object,
      default : ()=>({})
    },
  },
  computed:{
    ...mapGetters({
      userId: 'auth/getUserId',
      feedComments: 'feed/feedComments'
    }),
  },
  watch: {
    visible: {
      async handler (newVal, oldVal) {
      if (newVal) {
        this.fetchCommentLoader = !this.fetchCommentLoader
        await this.fetchComments({postId: this.feedObject?.id})
        this.fetchCommentLoader = !this.fetchCommentLoader
      }
    }, immediate: true
  },
  },
  methods: {
    ...mapActions({
      fetchComments: 'feed/fetchComments',
      createFeedComments: 'feed/createFeedComments',
      userId: 'auth/getUserId',
    }),
    closeModal() {
      this.$emit("closeViewFeedModal", false);
    },
    updateReaction() {
      this.reRender = this.reRender + 1;
    },
    async createComment(comment){
      // console.log('COMMENt', comment)
      try{
        const payload = {
            user: this.userId,
            text: comment,
            parent: null
        }
        this.commentLoader = !this.commentLoader
        await this.createFeedComments({postId:this.feedObject?.id, payload })
        // await this.fetchComments({postId: this.feedObject?.id})
      }
      catch(err){
        console.log(err)
        // this.$showToast({message:''})
      } 
      finally{
        this.commentLoader = !this.commentLoader
      }
      
    }
  },
};
</script>
<style lang="scss">
.view-feed-modal {
  width: 100% !important;
  top: 5rem !important;
  max-width: 70rem;
  padding: 0 4rem;

  .ant-modal-content {
    box-shadow: 0px 0px 94px #00000047;

    .ant-modal-close {
      margin: 1.5rem 1.5rem 0 0;

      .ant-modal-close-x {
        padding: 0.5rem;
        width: 4rem;
        height: 3.8rem;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        .anticon {
          color: $color-black;
          font-size: 2rem;
        }
      }
    }

    .ant-modal-body {
      .view-feed-modal-container {
        padding-bottom: 1rem;
        &__header {
          padding: 2rem 2rem;
          position: sticky;
          top: 0;
          background-color: $color-white;
          border-start-end-radius: 1rem;
          border-start-start-radius: 1rem;
          &--user {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__image {
              width: 5rem;
              height: 5rem;
              margin-right: 1rem;
              cursor: pointer;
              border-radius: 100%;

              img {
                width: 100%;
                height: auto;
                border-radius: 100%;
                object-fit: contain;
                background-color: #ebebeb;
              }
            }

            &__details {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              h1 {
                font-size: 1.8rem;
                font-family: $font-primary-bold;
                line-height: 2.4rem;
                margin-bottom: 0.3rem;
                color: $color-black;
                cursor: pointer;
              }

              p {
                font-size: 1.4rem;
                color: $color-black;
                opacity: 0.6;
                font-family: $font-primary-medium;
                margin-bottom: 0;
                line-height: 1.7rem;
              }

              .time {
                font-size: 1.2rem;
                line-height: 1.5rem;
              }
            }
            @include respond(phone-x-small) {
              &__details {
                h1 {
                  font-size: 2rem;
                  line-height: 2.6rem;
                }
                .time {
                  font-size: 1.6rem;
                  line-height: 2.2rem;
                }
              }
            }
          }
        }
        &__body {
          max-height: calc(100vh - 21rem);
          overflow-y: auto;
          overflow-x: hidden;
           &::-webkit-scrollbar {
            width: 0.4rem;
            height: 0.4rem;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 0.8rem;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 0.8rem;
          }
          &--description {
            padding: 0 2.4rem 1.5rem;
            p {
              font-size: 1.6rem;
              font-family: $font-primary;
              line-height: 2.2rem;
              margin-bottom: 0;
              color: $color-black;
              @include respond(phone-x-small) {
                font-size: 1.8rem;
                line-height: 2.4rem;
              }
            }
          }
          &--repost-card {
            .glocal-repost-card {
              margin: 0 2rem 2rem;
              overflow: hidden;
              &__body {
                .upload-image {
                 background-size: cover;
                 height: 35rem; 
                }
              }
              .upload-article__header {
                height: 35rem;
              }
            }
          }
          &--footer {
            .user-react {
              padding: 1rem 1.5rem;
              border-top: 1px solid rgba(0, 0, 0, 0.2);
              width: calc(100% - 4rem);
              margin: 0 2rem;
              @include respond(phone-x-small) {
                padding: 1.5rem 2.6rem;
                &__outer-flex {
                  &--image-conatiner {
                    .image {
                      height: 2rem;
                      width: 2rem;
                      margin-left: -0.7rem;
                    }
                  }
                }
                &__name {
                  font-size: 1.6rem;
                  line-height: 2.2rem;
                }
              }
            }
            .action-bar {
              border-top: 1px solid $color-dark-grey-5;
              border-bottom: 1px solid $color-dark-grey-5;
              display: flex;
              align-items: center;
              width: calc(100% - 4rem);
              margin: 0 2rem;
              @include respond(tab-port) {
                border-bottom: none;
              }
              &__btn {
                width: 33.3%;
                border: none;
                outline: none;
                font-size: 1.6rem;
                font-family: $font-primary-medium;
                line-height: 2.2rem;
                gap: 1rem;
                color: $color-black;
                display: flex;
                align-items: stretch;
                justify-content: center;
                cursor: pointer;
                background-color: transparent;
                padding: 1.5rem 0;
                @include respond(phone-x-small) {
                  padding: 1.7rem 0;
                }
              }

              &__line {
                width: 1px;
                height: 2.3rem;
                background-color: $color-dark-grey-5;
                display: block;
              }

              .the-horizontal-reactions-bar {
                width: 33%;
                padding: 0;
                margin: 0 !important;

                &__reaction-status {
                  padding: 1.4rem 0;
                  border-end-start-radius: 2rem;
                  @include respond(phone-x-small) {
                    padding: 1.2rem 0;
                  }
                  &--reactions {
                    .image {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      img {
                        width: 2rem !important;
                      }
                    }

                    .title {
                      font-size: 1.6rem;
                      font-family: $font-primary-medium;
                      line-height: 2.4rem;
                    }
                  }
                }
              }
            }
            .comment-type-input {
              padding: 1rem 2rem;
            }

            // &::-webkit-scrollbar {
            //   width: 0.5rem;
            //   height: 0.5rem;
            // }

            // &::-webkit-scrollbar-track {
            //   background-color: transparent;
            //   border-radius: 0.8rem;
            // }

            // &::-webkit-scrollbar-thumb {
            //   background-color: $color-primary;
            //   border-radius: 0.8rem;
            //   height: 5rem;
            // }
          }
        }
      }
    }
  }
}
</style>
