<template>
  <div class="feed-comment-type-input">
    <img
      :src="loggedInUserDetail?.profile?.profile_picture"
      alt="Profile picture"
      class="feed-comment-type-input__profile-picture"
    />
    <textarea
      v-model="comment"
      name=""
      id="commentTextarea"
      cols="30"
      rows="1"
      placeholder="Add a comment..."
      class="feed-comment-type-input__textarea"
    ></textarea>
    <div class="feed-comment-type-input__buttons">
      <a-button @click.stop="createComment()" :loading="loadingState">
        <i class="icon icon-send"></i>
      </a-button>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {

props:{
  loadingState:{
    type: Boolean,
    default: () => (false)
  }
},

data: () => ({
  comment: '',

}),

computed:{
  ...mapGetters({
    loggedInUserDetail: 'profile/userInformation'
  }),
},

watch:{
  loadingState(state){
    if (!state){
      this.comment = ''
    }
  }
},

methods:{

  createComment(){
    this.$emit('create', this.comment)
  }

}


}


</script>
<style lang="scss">
.feed-comment-type-input {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem 0;
  &__profile-picture {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    object-fit: cover;
  }
  &__textarea {
    padding: 1.1rem 1.5rem 1rem;
    margin: 0;
    border: 1px solid $color-dark-grey-5;
    font-size: 1.4rem;
    font-family: $font-primary;
    box-shadow: none;
    outline: none;
    line-height: 1.7rem;
    color: $color-black;
    background-color: transparent;
    resize: none;
    width: 100%;
    border-radius: 3rem !important;
    &::placeholder {
      color: $color-black;
      opacity: 0.5;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    padding: 0;

    & .icon-send {
      font-size: large;
    }
    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .ant-btn {
      width: 4.5rem;
      background-color: $color-primary;
      border: none;
      outline: none;
      height: 3rem;
      color: $color-white;
      border-radius: 100px !important;
      padding: 0 !important;
      span {
        line-height: normal;
      }
      .anticon {
        .anticon-spin {
          font-size: 1.4rem;
          color: $color-primary;
        }
      }
      &:hover,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
      .icon {
        font-size: 1.5rem;
        display: block;
        color: $color-white;
      }
    }
  }
}
</style>
